const cssProperties = () => {
    return {
        subtitle: {
            fontWeight: 900,
        },
        inputs: {
            background: 'white',
            borderColor: 'external light orange !important',
            width: 280
        },
        line: {
            textDecoration: "underline",
            fontSize: 13
        },
        btn: {
            fontWeight: 900,
            textTransform: "capitalize"
        },
        notExistsError: {
            color: "red",
            fontSize: 11,
            fontWeight: 500,
            textAlign: "center"
        },
        loginError: {
            color: "red",
            fontSize: 11,
            fontWeight: 500,
            textAlign: "center"
        }
    }
}

module.exports = { cssProperties }